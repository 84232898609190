import React from 'react'
import styled from 'styled-components'
import bg from './card.png'
import { Button } from '../../../../components/Navbar/Navbar';
import Bid from './components/Bid';
import Countdown from "react-countdown";
import Counter from './counter.png'

const Sec = styled.section`
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
  
    @media only screen and (max-width: 768px) {
        padding: 2rem 0;
        background-size: auto 100%;
      }
`;


const Center = styled.div`
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${bg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 1100px;
    padding: 4rem 0;


  @media only screen and (max-width: 1400px) {
      width: 96%;
     
  }
  @media only screen and (max-width: 1000px) {
      width: 99%;
     
  }
  @media only screen and (max-width: 768px) {
    background-image: none;
    border: 1px solid rgba(255,255,255,0.8);
    
  }
  @media only screen and (max-width: 600px) {
      width: 99%;
      padding: 2rem 0 3rem 0;
      border-radius: 1rem;
  }
`
const Count = styled.span`
  background-image: url('${Counter}');
  background-size: 100% 100%;
  width: 5rem;
  height: 5rem;
  font-size: 3.5rem;
  
  color: #FFF;
  padding: 0 0.5rem;
`
const T = styled.p`
    font-family: myFirstFont;
    color: #FFF;
    letter-spacing: 0.06rem;
    font-weight: lighter;
    font-size: 1.5rem;
`

const H = styled.h1`
    font-family: myFirstFont;
    color: #FFF;
    letter-spacing: 0.06rem;
    font-weight: lighter;
    font-size: 3rem;
    margin: 3rem 0;

    @media only screen and (max-width: 768px) {
      font-size: 2rem;
    }
`

const Align = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
 
  width: 30rem;
  margin: 0 0 2.5rem 0;

  @media only screen and (max-width: 630px) {
    flex-direction: column;
    width: 95%;
}
`

const Page7 = () => {

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      
      return 
    } else {
      
      return (
        <span style={{margin:'1.5rem 0 0 0'}}>
          <Count>{hours}</Count>:<Count style={{margin:'0 1rem'}}>{minutes}</Count>:<Count>{seconds}</Count>
          <div style={{display:'flex',alignItems:'center',width:'15rem',justifyContent:'space-around',margin:'0 0 2.5rem 0'}}>
            <p style={{color:'#FFF'}} >DAYS</p>
            <p style={{color:'#FFF'}} >HRS</p>
            <p style={{color:'#FFF'}} >MINS</p>
          </div>
        </span>
       );
    }
  };
  
    return (
        <Sec >

          <Center>
            
            <Bid />

            <H>COMMING SOON</H>
            {/* <Countdown date={Date.now() + 900000} renderer={renderer} /> */}

            <Align>
              <T>START DATE</T>
              <T>2022-04-25</T>
            </Align>

            <Button>
              Connect Wallet
            </Button>
          
          </Center>

        </Sec>
    )
}

export default Page7

import React,{useState} from 'react'
import styled from 'styled-components'
import Card from './components/Card'
import bg from './bg.png'

const Sec = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: url(${bg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: #000;

    padding: 8rem 0;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }

`

const Width  = styled.div`
    width: 1300px;
    display: grid;
    margin: 1rem 0 0 0;
   grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));

   place-items: center;

    @media only screen and (max-width: 1300px) {
        width: 100%;
       
    }

    @media only screen and (max-width: 600px) {
       
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        
    }
`
const H = styled.h1`
    font-family: myFirstFont;
    color: #FFF;
    letter-spacing: 0.06rem;
    font-weight: lighter;
    font-size: 3.5rem;
    max-width: 20rem;
`

const Page6b = () => {

    return (
        <Sec>
          <Width>
             
            <H>HOW DOES IT WORK?</H>
            <Card content='All bids are collected in ETH and auction will run for a duration of X weeks, ending on XXXX.'/>
            <Card content='Only the top 300 bids will be accepted and the remaining that falls below the 300th bid will be fully refunded to the user.'/>
            <Card content='Successful NFT bidders will be airdropped in total of an 8% allocation of $CHIMP tokens in accordance to the highest bidder.'/>
            <Card content='Successful NFT bidders will be awarded allocation in any new protocols deployed on ChimpWorld. '/>
               
          </Width>
        </Sec>
    )
}

export default Page6b

import React from 'react'
import styled from 'styled-components'
import bg from './bg2.png'
import Card from './components/Card';

const Sec = styled.section`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
    background-color: #000;
    width: 100%;
    padding: 3rem 0 10rem 0;
    background-image: url('${bg}');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    
    @media only screen and (max-width: 768px) {
        
        padding:  2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;

const Width = styled.div`
      width: 1050px;
      display: flex;
        
        align-items: center;
        justify-content: space-between;
    
    @media only screen and (max-width: 1400px) {
        width: 95%;
    }
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

`

const CardContainer = () => {
  
    return (
        <Sec>

            <Width>
               
               <Card />
               <Card />

            </Width>

        </Sec>
    )
}

export default CardContainer






import React from 'react'
import styled from 'styled-components'
import bg from './bg2.png'
import TokkenCard from './components/TokkenCard';
import img1 from './1.png'
import img2 from './2.png'
import img3 from  './3.png'

const Sec = styled.section`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
    background-color: #000;
    width: 100%;
    padding: 3rem 0 10rem 0;
    background-image: url('${bg}');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    
    @media only screen and (max-width: 1500px) {
        background-size: auto 100%;
        background-position: right center;
    }
    

    @media only screen and (max-width: 768px) {
        
        padding:  2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;

const Width = styled.div`
      width: 1050px;
      display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

    
    @media only screen and (max-width: 1400px) {
        width: 95%;
    }
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

`

const Tokken = () => {
  
    return (
        <Sec>

            <Width>
               
               <TokkenCard image={img1} />
               <TokkenCard image={img2} />
               <TokkenCard image={img3} />
               <TokkenCard image={img1} />
               <TokkenCard image={img2} />
               <TokkenCard image={img3} />

            </Width>

        </Sec>
    )
}

export default Tokken






import React from 'react'
import styled from 'styled-components'
import eth from './eth.svg'

const Hb = styled.p`
    
    @font-face {
      font-family: myFirstFont;
      src: url('/font.ttf');
    }
      font-weight: 400;
        color: #FFFFFF;
        font-size: 2.5rem;
        
        font-family: myFirstFont;
        margin: 0.5rem 0 0 0.5rem;
    @media only screen and (max-width: 768px) {
      
        font-size: 1.5rem;
      }
`;

export const T = styled.p`
    font-family: myFirstFont;
    color: #FFF;
    letter-spacing: 0.06rem;
    font-weight: lighter;
`

const Bid = () => {

    const StyledBid = styled.div`
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 800px) {
      
          width: 80%;
        }
        @media only screen and (max-width: 768px) {
      
          width: 90%;
        }
        @media only screen and (max-width: 630px) {
      
          flex-direction: column;
        }
    `

  return (
    <StyledBid>
        <div style={{margin:'1rem 0 0 0'}}>
            <T>HIGHEST BID POSITION</T>
            <div style={{display:'flex',alignItems:'center',margin:'0.5rem 0',justifyContent:'center'}}> 
                <img src={eth} alt='' style={{height: '2.5rem'}}/>
                <Hb>75.4500</Hb>
            </div>
        </div>
        <div style={{margin:'1rem 0 0 0'}}>
            <T>LOWEST BID POSITION</T>
            <div style={{display:'flex',alignItems:'center',margin:'0.5rem 0',justifyContent:'center'}}> 
                <img src={eth} alt='' style={{height: '2.5rem'}}/>
                <Hb>75.4500</Hb>
            </div>
        </div>
    </StyledBid>
  )
}

export default Bid
import React from 'react'
import styled from 'styled-components';
import Tilt from 'react-parallax-tilt';
import bg from './widecard.png'

const T = styled.p`
    color: #FFF;
      font-weight: 300;
    font-size: 1.1rem;
 
    line-height: 1.8;
    
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

const WideCard = (props) => {
    const Box= styled.div`
        height: 15rem; 
        width: 100%;   
        background-image: url('${bg}');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        padding: 2rem;
        margin: 2rem 0 0 0;
     

        @media only screen and (max-width: 600px) {
           background-image: none;
           margin: 6rem 0 0 0;
        }
    `;

    const Flex = styled.div`
        width: 100%;
        display: grid;
         margin: 1rem 0 0 0;
        grid-template-columns: repeat(4, minmax(16rem, 1fr));
       
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 1300px) {
            grid-template-columns: repeat(2, minmax(16rem, 1fr));
        }

        @media only screen and (max-width: 600px) {
            grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
        }
    `

    return (
        <Box>
           <T>
            $CHIMP LP providers will also earn from: 
           </T>
           <Flex>
                <div>
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16.0005" cy="16.0371" r="16" fill="url(#paint0_linear_2_121)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00049 14.9577C9.07124 15.3833 11.7345 16.207 13.7505 17.5021C16.1182 14.3716 20.3715 11.4431 25.0005 9.03711C20.6047 13.311 16.9672 18.1608 14.5005 23.0371C12.5122 20.25 10.3402 17.656 7.00049 14.9577Z" fill="white"/>
                        <defs>
                        <linearGradient id="paint0_linear_2_121" x1="16.0005" y1="0.0371094" x2="16.0005" y2="32.0371" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#712AE1"/>
                        <stop offset="1" stop-color="#C62AE1"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    <T>NFT market place sale fees </T>
                </div>
                <div>
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16.0005" cy="16.0371" r="16" fill="url(#paint0_linear_2_121)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00049 14.9577C9.07124 15.3833 11.7345 16.207 13.7505 17.5021C16.1182 14.3716 20.3715 11.4431 25.0005 9.03711C20.6047 13.311 16.9672 18.1608 14.5005 23.0371C12.5122 20.25 10.3402 17.656 7.00049 14.9577Z" fill="white"/>
                        <defs>
                        <linearGradient id="paint0_linear_2_121" x1="16.0005" y1="0.0371094" x2="16.0005" y2="32.0371" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#712AE1"/>
                        <stop offset="1" stop-color="#C62AE1"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    <T>Testing rights for CHIMP games</T>
                </div>
                <div>
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16.0005" cy="16.0371" r="16" fill="url(#paint0_linear_2_121)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00049 14.9577C9.07124 15.3833 11.7345 16.207 13.7505 17.5021C16.1182 14.3716 20.3715 11.4431 25.0005 9.03711C20.6047 13.311 16.9672 18.1608 14.5005 23.0371C12.5122 20.25 10.3402 17.656 7.00049 14.9577Z" fill="white"/>
                        <defs>
                        <linearGradient id="paint0_linear_2_121" x1="16.0005" y1="0.0371094" x2="16.0005" y2="32.0371" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#712AE1"/>
                        <stop offset="1" stop-color="#C62AE1"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    <T>Airdrops from CHIMP games </T>
                </div>
                <div>
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16.0005" cy="16.0371" r="16" fill="url(#paint0_linear_2_121)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00049 14.9577C9.07124 15.3833 11.7345 16.207 13.7505 17.5021C16.1182 14.3716 20.3715 11.4431 25.0005 9.03711C20.6047 13.311 16.9672 18.1608 14.5005 23.0371C12.5122 20.25 10.3402 17.656 7.00049 14.9577Z" fill="white"/>
                        <defs>
                        <linearGradient id="paint0_linear_2_121" x1="16.0005" y1="0.0371094" x2="16.0005" y2="32.0371" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#712AE1"/>
                        <stop offset="1" stop-color="#C62AE1"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    <T>NFT drops from collaborations</T>
                </div>
           </Flex>
        </Box>
    )
}

export default WideCard

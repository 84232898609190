import React from 'react'
import styled from 'styled-components';
import Tilt from 'react-parallax-tilt';
import bg from './1.png'

const T = styled.p`
    color: #FFF;
      font-weight: 300;
    font-size: 1.2rem;
 
    line-height: 1.8;
    
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

const Card = (props) => {
    const Box= styled(Tilt)`
        height: 15rem; 
        width: 25rem;   
        background-image: url('${ bg }');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        margin: 2rem 0 0 0;
  
        @media only screen and (max-width: 600px) {
            min-height: 15rem; 
            width: 95%;
        
        }
    `;

    return (
        <Box>
           <T>
               {props.content ? props.content : 'NFT holders will get a free 8% airdrop allocation of total supply.'}
           </T>
        </Box>
    )
}

export default Card

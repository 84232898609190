import React from 'react'
import Page1 from './components/Page1/Page1'
import Tokken from './components/Tokken/Tokken'

const Voting = () => {
  return (
    <>
        <Page1 />
        {/* <Tokken /> */}
    </>
  )
}

export default Voting
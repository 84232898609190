import React,{useState, useEffect, useRef } from 'react'
import './Navbar.css';
import logo from './logo.svg';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Burger, Menu } from './components';
import FocusLock from 'react-focus-lock';
import theme from "styled-theming";
import { useSelector, useDispatch } from "react-redux";
import dis from './dis.svg';
import med from './tele.svg'
import twi from './Twitter.svg';
import { Outlet, Link } from "react-router-dom";

export const backgroundColor = theme("theme", {
  light: "#000000",
  dark: "#E5E5E5",
});  

export const Link1 = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin: 0 0 0 5rem;
}

@media only screen and (max-width: 768px) {
    margin: 0 0 0 2rem;
}

`;

const Link2 = styled(Link)`
  display: flex;
  margin: 0;
  align-items: center;
  text-decoration: none;
  color: #FFF !important;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    
  }

`;

const A = styled.a`
  display: flex;
  margin: 0;
  align-items: center;
  text-decoration: none;
  color: #FFF !important;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    
  }

`;

const Mobile = styled.div`
  display: none;
  @media only screen and (max-width: 1200px) {
    display: block;
  }`

const PC = styled.div`
display: block;
@media only screen and (max-width: 1200px) {
  display: none;
}`

export const Button = styled(motion.button)`
  background: rgba(142, 44, 226, 0.2);
  border: 2px solid #AA22C0;
  border-radius: 5px;
  padding: 0 1.5rem;
  color: #FFF;
  height: 3.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
`

export const IconContainer = styled.div`
  width: 12rem;
  display: flex;
  justify-content: space-between;
  align-items:center;
  margin-right: 5rem;
 // background: red;
  
  // @media only screen and (max-width: 1600px) {
  //   width: 25rem;
  // }
  @media only screen and (max-width: 1600px) {
    width: 12rem;
  }
  @media only screen and (max-width: 1250px) {
    display: none;
  }
`
export const Image = styled(motion.img)`
  cursor: pointer;
`

const Navbar = () => {

  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";

const [navbar, setNavbar] = useState(false)

const changeBackground = () => {
    // console.log(window.scrollY)
    if (window.scrollY >= 100) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })  

    return (
      <>
        <nav className={navbar ?  "navActiveLight": "nav"} >

         
          <Link1 to="/" spy={true} smooth={true} ><img src={logo} alt="Logo" className={navbar ? "LogoActive" : "Logo"} /></Link1>
         
          <div style={{display:'flex',alignItems:'center'}}>
         
          <PC style={{margin:'0 2rem 0 0'}}>
            <ul className="list">
                <Link2 to="/" >HOME</Link2>
                <Link2 to="/nft" >NFT AUCTION</Link2>
                <Link2 to="/sale" >GET $CHIMP</Link2> 
                <Link2 to="/pool" >STAKE</Link2>
                <A href='https://drive.google.com/file/d/10QB81ipalpD6pBU77Q6X_ar95pITYdYi/view?usp=sharing'
                  target='_blank'>
                  DOCS
                </A>
                <Link2 to="/vote" >VOTE</Link2>
            </ul> 
          </PC>

          <PC style={{margin:'0 0 0 2rem'}}>
            <IconContainer>
              <a href='https://discord.com/invite/chimpdao' target='_blank'>
                <Image
                  src={dis}
                  whileHover={{ scale: 1.1,boxShadow:"0 0 25px #C92CE2",borderRadius:'50%'}}
                  whileTap={{ scale: 0.7 }}
                  alt="Logo"  
                />
              </a>
              <a href='https://twitter.com/chimpdao' target='_blank'>
                <Image src={twi} alt="Logo" 
                  whileHover={{ scale: 1.1,boxShadow:"0 0 25px #C92CE2",borderRadius:'50%'}}
                  whileTap={{ scale: 0.7 }}
                />
              </a>
              <a href='https://t.me/ChimpDAO' target='_blank'>
                <Image src={med} alt="Logo" 
                  whileHover={{ scale: 1.1,boxShadow:"0 0 25px #C92CE2",borderRadius:'50%'}}
                  whileTap={{ scale: 0.7 }}
                />
              </a>
              {/* <Button 
                  type="button"
                  whileHover={{ scale: 1.1,boxShadow:"0 0 25px #C92CE2"}}
                  whileTap={{ scale: 0.7 }}
              >Connect Wallet</Button> */}
            </IconContainer> 
          </PC>

          </div>
        
          <Mobile ref={node}>
            <FocusLock disabled={!open}>
              <Burger  open={open} setOpen={setOpen} aria-controls={menuId} />
              <Menu open={open} setOpen={setOpen} id={menuId} />
            </FocusLock>
          </Mobile>

        </nav>  
         <Outlet /> 
      </>
    )
}

export default Navbar

import React from 'react'
import bg from './bg.png'
import styled from 'styled-components'
import Countdown from "react-countdown";
import Counter from './counter.png'

const StyledSalePeriod = styled.div`
    background-image: url(${bg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 1050px;
    min-height: 55rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 0 5rem 0;

    @media only screen and (max-width: 1100px) {
        width: 99%;
    }

    @media only screen and (max-width: 600px) {
     
      background-image: none;
     
    }
`

const Count = styled.span`
  background-image: url('${Counter}');
  background-size: 100% 100%;
  width: 5rem;
  height: 5rem;
  font-size: 3.5rem;
  
  color: #FFF;
  padding: 0 0.5rem;
`
const T = styled.p`
   
    color: #FFF;
    letter-spacing: 0.4rem;
  
    font-size: 1.3rem;
`
const Box = styled.div`
    background-color: #312A3F;
    width: 80%;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem ;
    @media only screen and (max-width: 1000px) {
      width: 90%;
  }
`
const Button = styled.button`
    background: linear-gradient(91.69deg, #C429E0 -12.45%, #662ACA 100.44%);
    border: none;
    width: 80%;
    padding: 0 2rem;
    color: #FFF;
    height: 7rem;
    cursor: pointer;
    font-size: 2.5rem;
    font-weight: 500;
    @media only screen and (max-width: 1000px) {
      width: 90%;
  }
`
const Container = styled.div`
  width: 80%;
  @media only screen and (max-width: 1000px) {
    width: 90%;
}
`
const H = styled.h1`
  
    @font-face {
      font-family: myFirstFont;
      src: url('/font.ttf');
    }
      font-weight: 200;
        color: #FFFFFF;
        font-size: 3rem;
        font-family: myFirstFont;
        margin: 2rem 0;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 2rem;
      }
`;
export const Input = styled.input`
  height: 60%;
  font-size: 2rem;
  max-width: 50%;
  background-color: transparent;
  border: none;
  color: #FFF;
  &:focus-visible{
    border: none;
  }
  &::placeholder{
    color: #FFF;
    padding: 0 0 0 2rem;
  }
  &:focus, &:focus, &:focus{
    outline: none;
}
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
&[type=number] {
  -moz-appearance: textfield;
}
`

const SalePeriod = () => {

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          
          return 
        } else {
          
          return (
            <span style={{margin:'1.5rem 0 0 0'}}>
              <Count>{hours}</Count>:<Count style={{margin:'0 1rem'}}>{minutes}</Count>:<Count>{seconds}</Count>
              <div style={{display:'flex',alignItems:'center',width:'15rem',justifyContent:'space-around',margin:'0 0 2.5rem 0'}}>
                <p style={{color:'#FFF'}} >DAYS</p>
                <p style={{color:'#FFF'}} >HRS</p>
                <p style={{color:'#FFF'}} >MINS</p>
              </div>
            </span>
           );
        }
      };

  return (
    <StyledSalePeriod>
        <T>SALE PERIOD ENDS</T>
        {/* <Countdown date={Date.now() + 900000} renderer={renderer} /> */}
        <H>COMMING SOON</H>
        <Box/>
        <Container style={{margin:'3rem 0'}}>
            <p style={{color:'rgba(255,255,255,0.8)',fontSize:'1.3rem',letterSpacing:'0.1rem',margin:'0 0 0.75rem 0'}}>
                I want to deposit</p>
            <Box  style={{width:'100%'}}>
              <Input type='number' placeholder='...' />
            </Box>
        </Container>
        <Container style={{margin:'0 0 3rem 0'}}>
            <p style={{color:'rgba(255,255,255,0.8)',fontSize:'1.3rem',letterSpacing:'0.1rem',margin:'0 0 0.75rem 0'}}>
                Locked Amount</p>
            <Box  style={{width:'100%'}}>
              <Input type='number' placeholder='...' />
            </Box>
        </Container>
        <Button>
            DEPOSIT
        </Button>
    </StyledSalePeriod>
  )
}

export default SalePeriod
import React from 'react'
import styled from 'styled-components';
import Card from './components/Card';
import i1 from './1.png'
import i2 from './2.png'
import i3 from './3.png'
import i4 from './4.png'

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction; column;
    justify-content: center;
    background-color: #000;

    @media only screen and (max-width: 768px) {
        min-height: 100vh;
        width: 100%;
        padding: 2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;

const Width = styled.div`
      width: 1300px;
      display: flex;
      flex-direction: column;
  
      align-items: center;
      @media only screen and (max-width: 1300px) {
        width: 95%;
      }



`


const Ht = styled.h1`
   
    font-size: 3rem;
    font-weight: bold;
    margin: 10rem 0 5rem 0;
    @font-face {
        font-family: myFirstFont;
        src: url('/font.ttf');
      }
     
          color: #FFFFFF;
          
          
          font-family: myFirstFont;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.5rem;
        font-weight: bold;
      }
`;

const Resposive = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @media only screen and (max-width: 768px) {
        display: grid;
         
        grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
       
        // align-items: center;
        // justify-content: space-between;
        place-items: stretch center;
      }

      
`

const Page6a = () => {
    return (
        <Sec>
            <Width>
                <Ht>TOP 10 BIDS</Ht>
                <Resposive>
                    <Card img={i1} heading="POSITION" />
                    <Card img={i2} heading="USER ADDRESS" />
                    <Card img={i3} heading="BID AMOUNT" special/>
                    <Card img={i4} heading="BID TIME" />
                </Resposive>
            </Width>
        </Sec>
    )
}

export default Page6a

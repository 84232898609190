import React from 'react'
import styled from 'styled-components'
import { H, T } from '../Page2/Page2'
import bg from './bg4.png'
import Card from './components/Card'
import roadImg from './img.png'

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
     background-image: url(${bg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    flex-direction: column;
    padding: 4rem 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 1500px) {
      background-size: auto 100%;
      background-position: left center;
  }
  

    @media only screen and (max-width: 998px) {
        min-height: 100vh;
        width: 100%;
        padding: 2rem 0;
        flex-direction: column;
        background-size: auto 100%;
      }
`;

const Width = styled.div`
      width: 1500px;
      display: flex;
      flex-direction: column;
      
      align-items: center;
      @media only screen and (max-width: 1500px) {
        width: 99%;
      }
   
      @media only screen and (max-width: 998px) {
        
        justify-content: center;
        flex-direction column;
      
      }
`
const RoadMap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const CardContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 -10rem 0;

    @media only screen and (max-width: 1380px) {
        
      margin: 0;
    
    }
`
const CardContainer1 = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -4rem 0 0 0;

    @media only screen and (max-width: 1380px) {
        
      margin: 0;
    
    }
`

const RoadMapContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 -10rem 0;
    Width: 1200px;

    @media only screen and (max-width: 1380px) {
        
      margin: 0;
      width: 95%;
      justify-content: space-evenly;
    
    }
    @media only screen and (max-width: 630px) {
        
      flex-direction: column;
    
    }
`

const RoadMapContainer1 = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: -20rem 0 0 0;
//background: red;

    @media only screen and (max-width: 1520px) {
        width: 96%;
    }

    @media only screen and (max-width: 1380px) {
        
      margin: 0;
      
      justify-content: space-evenly;
    
    }

    @media only screen and (max-width: 630px) {
        
      flex-direction: column;
    
    }

`
const RoadMapImg = styled.div`
    width: 50rem;
    height: 40rem;

    @media only screen and (max-width: 1500px) {
        
      width: 40rem;
    height: 30rem;
    
    }
    @media only screen and (max-width: 1380px) {
        
      display: none;
    
    }
`

const Page4 = () => {
  
    return (
        <Sec id="core">
            <H>ROADMAP</H>

            <Width>

              <CardContainer style={{}}>
                <Card
                  heading='TOWN HALL (VOTING)'
                  content='The Chimp Town Hall serves as the place to submit ChimpDAO proposals that willset the path for Chimp World and allow Chimps to vote on them.'
                />
              </CardContainer>

              <RoadMap>
                <RoadMapContainer style={{}}>
                  <Card
                    heading='CHIMP CARNIVAL (GAMES)'
                    content='Chimp Carnival serves as the gateway to entertainment in the form of games,and /or lotteries. For those of you who enjoy a little fun and chance, this is the place for you.'
                  />
                  <Card 
                    heading='CHIMP RESERVES (STAKING)'
                    content='Chimps prosper by preserving valuable resources. Here at the Chimp Reserves,Chimps are able to stake their idle $CHIMP or Liquidity Provider tokens.'
                  />
                </RoadMapContainer>
                <RoadMapImg>
                  <img src={roadImg} alt='' style={{height:'100%',width:'100%'}} />
                </RoadMapImg>
                <RoadMapContainer1>
                  <Card
                    heading='THE ROCKET PAD'
                    content='Chimp World’s ecosystem spans beyond just this world. Visit the Rocket Pad to see more of the Chimp Ecosystem!'
                  />
                  <Card 
                    heading='ART GALLERY (NFT MARKET)'
                    content='In order to enable Chimps to be exposed to other thriving markets, it is crucial to have an easy, safe and efficient decentralized exchange which is the Chimp Exchange.'
                  />
                </RoadMapContainer1>
              </RoadMap>

              <CardContainer1>
                <Card 
                  heading='CHIMP EXCHANGE (DEX)'
                  content='In order to enable Chimps to be exposed to other thriving markets, it is crucial to have an easy, safe and efficient decentralized exchange which is the Chimp Exchange.'
                />
              </CardContainer1>
     
            </Width>

        </Sec>
    )
}

export default Page4

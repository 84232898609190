import React from 'react'
import styled from 'styled-components'
import bg from './card.png'

const StylePool = styled.div`
    background-image: url(${bg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 1000px;
    padding: 5rem 5rem 6rem 5rem;
    display: grid;
    place-items: center;

    @media only screen and (max-width: 1000px) {
      width: 100%;
    }


    @media only screen and (max-width: 768px) {
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    }

`

const H = styled.p`
  
    @font-face {
      font-family: myFirstFont;
      src: url('/font.ttf');
    }
      font-weight: 200;
        color: #FFFFFF;
        font-size: 2rem;
        text-align: center;
        font-family: myFirstFont;
       
        margin: 0 0 2rem 0;
        
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
        margin: 1rem 0 0 0;
      }
`;

const Lower = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 700px;

    @media only screen and (max-width: 900px) {
      width: 95%;
     
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
     flex-direction: column;
    }
`

const Rewards = () => {
  return (
    <StylePool>
        <H>MY REWARDS</H>
        <Lower>
            <div  style={{margin:'2rem 0 0 0'}}>
                <p style={{color:'rgba(255,255,255,0.8)',fontSize:'1.3rem',letterSpacing:'0.1rem',margin:'0 0 0.75rem 0'}}>
                CURRENT $CHIMP AMOUNT</p>
                <H>3,679,531</H>
            </div>
            <div  style={{margin:'2rem 0 0 0'}}>
                <p style={{color:'rgba(255,255,255,0.8)',fontSize:'1.3rem',letterSpacing:'0.1rem',margin:'0 0 0.75rem 0'}}>
                EST $CHIMP PER DAY</p>
                <H>51%</H>
            </div>
        </Lower>
    </StylePool>
  )
}

export default Rewards
import React from 'react'
import Page1 from './components/Page1/Page1'
import Page2 from './components/Page2/Page2'
import Page3a from './components/Page3a/Page3a'
import Page4 from './components/Page4/Page4'
import Page5 from './components/Page5/Page5'

const Home = () => {
  return (
    <>
        <Page1 />
        <Page2 />
        <Page3a />
        <Page4 />
        <Page5 />
    </>
  )
}

export default Home
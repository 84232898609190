import React from 'react'
import styled from 'styled-components';
import Tilt from 'react-parallax-tilt';
import bg from './bg.png'

const T = styled.p`
    color: #FFF;
    
    font-size: 1.2rem;
 
    line-height: 1.4;
    text-align: center;
    
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

const Card = (props) => {
    const Box= styled(Tilt)`
        height: 30rem; 
        width: 30rem;   
        background-image: url('${bg}');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: ${props.special ? '4.2rem 2rem 2rem 2rem ' : '2rem' };
            margin: 1rem 0 0 0;
      
        @media only screen and (max-width: 768px) {
            height: 18rem; 
            width: 18rem;
            
        }
    `;

    const Line = styled.div`
        height: 4.52px;
        width: 100%;
        background: radial-gradient(47.21% 7328399.32% at 48.6% 0%, #821DC0 0%, rgba(69, 38, 134, 0) 100%);
    `

    return (
        <Box>
           <T>
               {props.heading1 ? props.heading1 : 'Heading1'}
           </T>
            <Line />
            <T>
               {props.heading2 ? props.heading2 : 'Heading2'}
           </T>
        </Box>
    )
}

export default Card

import React from 'react'
import styled from 'styled-components';
import Tilt from 'react-parallax-tilt';
import bg from './bg.png'
import { Button } from '../../../../../components/Navbar/Navbar'

const T = styled.p`
    color: #FFF;
    
    font-size: 1rem;
 
    line-height: 1.2;
    margin: 1rem 0 0 0;
    letter-spacing: 0.1rem;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

const H = styled.p`
@font-face {
    font-family: myFirstFont;
    src: url('/font.ttf');
  }
    font-weight: 400;
      color: #FFFFFF;
      font-size: 1.3rem;

      font-family: myFirstFont;
  @media only screen and (max-width: 768px) {
    
      font-size: 1rem;
    }
`;

const TokkenCard = (props) => {
    const Box= styled.div`
        height: 13rem; 
        width: 100%;   
        background-image: url('${bg}');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        margin: 3rem 0 0 0;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

      
        @media only screen and (max-width: 800px) {
           flex-direction: column;
           background-image: none;
           height: auto; 
           border: 1px solid rgba(255,255,255,0.6);
           padding: 2rem 1rem;
        }
    `;

    const Img = styled.div`
        height: 11rem;
        width: 30%;

        @media only screen and (max-width: 800px) {
          width: 90%;
           
       }
        
    `
    const Content = styled.div`

        width: 50%;

        @media only screen and (max-width: 800px) {
          width: 90%;
          margin: 2rem 0 0 0;
       }
        
    `
    const ButtonContainer = styled.div`
   
        display: flex;
        align-items: flex-end;
        height: 10rem;

        @media only screen and (max-width: 800px) {
          width: 90%;
          height: 4rem;
       }
        
    `

    return (
        <Box>

          <Img>
            <img src={`${props.image ? props.image : ''}`} alt='' style={{width:'100%',height:'100%'}} />
          </Img>
          <Content>
            <H>{props.heading2 ? props.heading2 : 'HEADING'}</H>
            <T>{props.content ? props.content : 'Project introduction content, project introduction text content description; project introduction text content description, project introduction text content description;'}</T>
          </Content>
          <ButtonContainer>
            <Button style={{height:'3rem'}}>
                Details
            </Button>
          </ButtonContainer>
            
        </Box>
    )
}

export default TokkenCard

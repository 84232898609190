import React from 'react'
import styled from 'styled-components'
import balance from './balance.png'
import { Input } from '../../../../PublicSale/components/Page1/components/SalePeriod'

const StylePool = styled.div`
    background-image: url(${balance});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 1000px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5rem 0 0 0;

    @media only screen and (max-width: 1100px) {
        width: 99%;
    }

    @media only screen and (max-width: 600px) {
     
      background-image: none;
     
    }
`

const H = styled.p`
  
    @font-face {
      font-family: myFirstFont;
      src: url('/font.ttf');
    }
      font-weight: 200;
        color: #FFFFFF;
        font-size: 2rem;
        text-align: center;
        font-family: myFirstFont;
        
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
      }
`;

const Lower = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 700px;
`
const Box = styled.div`
    background-color: #312A3F;
    width: 80%;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem ;
    @media only screen and (max-width: 1000px) {
      width: 90%;
  }
  @media only screen and (max-width: 1000px) {
    width: 80vw;
}
`

const Button = styled.button`
    background: linear-gradient(91.69deg, #C429E0 -12.45%, #662ACA 100.44%);
    border: none;
    width: 80%;
    padding: 0 2rem;
    color: #FFF;
    height: 7rem;
    cursor: pointer;
    font-size: 2.5rem;
    font-weight: 500;
    margin: 4rem 0 3rem 0;

    @media only screen and (max-width: 768px) {
        width: 100%;
        font-size: 1.5rem; 
        padding: 0 1rem;
      }

`
const Container = styled.div`
      width: 100%;
      display: grid;
      place-items: center;
    

      @media only screen and (max-width: 768px) {
        width: 95%;
       
      }

`

const BalanceCard = () => {
  return (
    <StylePool>
        <p style={{margin:'0 0 3rem 0',color:'rgba(255,255,255)',letterSpacing:'0.3rem'}}>MY BALANCE</p>
        
            <div style={{margin:'1rem 0 4rem 0'}}>
                <p style={{color:'rgba(255,255,255,0.8)',fontSize:'1.3rem',letterSpacing:'0.1rem',margin:'0 0 0.75rem 0',textAlign:'center'}}>
                $CHIMP</p>
                <H>3,679,531</H>
            </div>
            <Container >
                <p style={{width:'80%',color:'rgba(255,255,255,0.8)',textAlign:'left',margin:'0 0 0.5rem 0'}}>MAX</p>
                
                    <Box>
                      <Input type='number' placeholder='...' />
                    </Box>
                
            </Container>
            <Button>
                CONNECT WALLET
            </Button>
    </StylePool>
  )
}

export default BalanceCard
import React from 'react'
import styled from 'styled-components'
import bg from './bg.png'
import cen from './center.png'
import data from './max.png'

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem  0;
    background-color:  #000000;
    background-repeat: no-repeat;
    background-image: url(${bg});
    background-size: 100% 100%;
    background-position:  center center;
  
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 1rem 0 2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;

const Width = styled.div`
      width: 1500px;
      display: flex;
    justify-content: center; 
      align-items: center;
      @media only screen and (max-width: 1500px) {
        width: 100%;
      }
   
   
`
const Center = styled.div`
      width: 50rem;
      height: 32rem;

      @media only screen and (max-width: 800px) {
        width: 90%;
        height: 25rem;
      }

      @media only screen and (max-width: 500px) {
        width: 99%;
        height: 18rem;
      }


      @media only screen and (max-width: 430px) {
        width: 99%;
        height: 16rem;
      }
`

const Page5 = () => {
  
    return (
        <Sec>
            <Width>
                <Center>
                    <img src={data} alt='' style={{width:'100%',height:'100%'}} />
                </Center>
            </Width>
        </Sec>
    )
}

export default Page5
